<template>
  <div>
    <el-card class="box-card">
      <el-form
        :model="record"
        :rules="rules"
        ref="recordRef"
        label-width="100px"
      >
        <el-form-item label="铜钱分类" prop="cate2_id">
          <el-cascader
            v-model="parent_list"
            :options="options"
            @change="handleChange"
            :props="{ expandTrigger: 'hover' }"
            :show-all-levels="false"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="评级公司" prop="price">
          <el-select v-model="record.corporation" placeholder="请选择评级公司">
            <el-option label="公博" value="公博"></el-option>
            <el-option label="华夏" value="华夏"></el-option>
            <el-option label="保粹" value="保粹"></el-option>
            <el-option label="闻德" value="闻德"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="price">
          <el-select v-model="record.score" placeholder="请选择分值">
            <el-option label="裸币" value="裸币"></el-option>
            <el-option label="70分" value="70"></el-option>
            <el-option label="72分" value="72"></el-option>
            <el-option label="75分" value="75"></el-option>
            <el-option label="78分" value="78"></el-option>
            <el-option label="80分" value="80"></el-option>
            <el-option label="82分" value="82"></el-option>
            <el-option label="85分" value="85"></el-option>
            <el-option label="88分" value="88"></el-option>
            <el-option label="90分" value="90"></el-option>
            <el-option label="92分" value="92"></el-option>
            <el-option label="95分" value="95"></el-option>
            <el-option label="98分" value="98"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据来源" prop="host_id">
          <el-select v-model="record.host_id" placeholder="请选择数据来源">
            <el-option
              v-for="item in hostList"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="规格">
          <el-input v-model="record.shape" style="width: 223px"></el-input>
        </el-form-item>
        <el-form-item label="编号">
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="有编号" name="first">
              <el-input
                v-model="record.cert"
                style="width: 223px"
                placeholder="请输入编号"
              ></el-input>
              <el-button type="info" @click="judge()" plain v-if="imgflag == 0"
                >验证图片</el-button
              >
              <el-tag type="success" v-else-if="imgflag == 1"
                >图片已存在，无需上传！</el-tag
              >
            </el-tab-pane>
            <el-tab-pane label="编号缺失" name="second">
              <el-tag>无需输入编号，直接上传图片即可！</el-tag>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item label="图片" v-if="imgflag == 0">
          <el-upload
            ref="upload2"
            class="upload-demo"
            drag
            action="/api/files/uploadPcgsImg"
            :on-success="imgSuccess"
            :before-upload="beforeuploadImg"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过3M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="成交价" prop="price">
          <el-input v-model="record.price" style="width: 223px"></el-input>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="record.transdate"
            value-format="yyyy-MM-dd"
            style="width: 223px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add()">提 交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "UserInput",
  data() {
    return {
      activeName: "first",
      imgflag: false,
      record: {},
      parent_list: [],
      hostList: [],
      options:[],
      rules: {
        name: [
          { required: true, message: "请输入系列", trigger: "blur" },
          { min: 1, max: 20, message: "长度在2到20之间", trigger: "blur" },
        ],
        cate1: [
          { required: true, message: "请选择正确的系列", trigger: "blur" },
        ],
        cate1: [
          { required: true, message: "请选择正确的系列", trigger: "blur" },
        ],
        cate2: [
          { required: true, message: "请选择正确的品名", trigger: "blur" },
        ],
        score: [
          { required: true, message: "请选择正确的分值", trigger: "blur" },
        ],
        corporation: [
          { required: true, message: "请选择评级公司", trigger: "blur" },
        ],
        shape: [{ required: true, message: "请输入规格", trigger: "blur" }],
        type: [
          { required: true, message: "请选择正确的状态", trigger: "blur" },
        ],
        host: [{ required: true, message: "请选择数据来源", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getCateList();
    this.getHostList();
  },
  methods: {
    getCateList() {
      //发起请求
      request.get("/admin/copper/category/getAllCategory", {}).then((res) => {
        this.options = res.data;
      });
    },
    getCate2List() {
      request
        .get("/common/getCopperCate2List", {
          params: {
            id: this.record.cate1_id,
          },
        })
        .then((res) => {
          this.cate2List = res.data;
        });
    },
    getHostList() {
      //发起Cate1请求
      request.get("/common/getCopperHostList", {}).then((res) => {
        this.hostList = res.data;
      });
    },
    handleChange(value) {
      console.log("触发" + value)
      this.record.cate2_id = value[value.length-1];
    },
    resetForm() {
      //重置
      console.log("开始重置");
      this.$refs.upload2.clearFiles();
      this.record.cert = "";
      this.record.price = "";
      this.record.shape = "";
      this.record.img = "";
      this.imgflag = 0;
    },
    add() {
      this.$refs.recordRef.validate((valid) => {
        console.log(valid);
        if (!valid) return;
        //发起网络请求
        request.post("/user/copper/input", this.record).then((res) => {
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.resetForm();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    judge() {
      console.log("执行！");
      if (
        this.record.cert == null ||
        this.record.cert == "" ||
        this.record.cert.length <= 4
      )
        return;
      //发起网络请求
      request
        .get("/user/copper/input", {
          params: {
            cert: this.record.cert,
          },
        })
        .then((res) => {
          if (res.data == "0") {
            this.imgflag = 1;
            this.record.img = "";
          } else {
            this.imgflag = 0;
            this.$message({
              type: "success",
              message: "可以上传！",
            });
          }
        });
    },
    imgSuccess(res) {
      this.record.img = res.msg;
    },
    beforeuploadImg(file) {
      const isLt3M = file.size / 1024 / 1024 < 5;

      console.log("图片上传：" + isLt3M);
      if (!isLt3M) {
        this.$message({
          message: "图片大小不能超过5M!请重新选择!",
          type: "warning",
        });
        return false; //必须加上return false; 才能阻止
      }
    },
  },
};
</script>

<style scoped>
</style>
